import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, onSnapshot, query, Unsubscribe } from "firebase/firestore";
import app from "../../config";

class DashGraficoPorHora {
	protected readonly colRefStr: string;
	protected readonly colRef: CollectionReference<DocumentData>;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/DashboardView/${eventId}/GraficoPorHora`;
		this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	private dbDoc(id: string): DocumentReference<DocumentData> {
		return doc(getFirestore(app), this.colRefStr, id);
	}

	on(listener: (data: any) => void): Unsubscribe {
		return onSnapshot(query(this.colRef), snapshot => {
			listener(snapshot.docs);
		});
	}

	onRecargas(listener: (data: any) => void): Unsubscribe {
		return onSnapshot(this.dbDoc('recargas'), (snapshot) => {
			listener(snapshot.data());
		});
	}

	onTickets(listener: (data: any) => void): Unsubscribe {
		return onSnapshot(this.dbDoc('tickets'), (snapshot) => {
			listener(snapshot.data());
		});
	}

	onConsumos(listener: (data: any) => void): Unsubscribe {
		return onSnapshot(this.dbDoc('consumos'), (snapshot) => {
			listener(snapshot.data());
		});
	}

	onBilheteria(listener: (data: any) => void): Unsubscribe {
		return onSnapshot(this.dbDoc('bilheteria'), (snapshot) => {
			listener(snapshot.data());
		});
	}

	onEntradasPorSexo(entranceId: string, sex: string, listener: (data: any) => void): Unsubscribe {
		console.log(listener);
		return onSnapshot(this.dbDoc(`entradasPorSexo/${entranceId}/${sex}`), (snapshot) => {
			listener(snapshot.data());
		});
	}
}

export default DashGraficoPorHora;
