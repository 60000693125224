import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";
import TableReport from "../TableReport";
import { calculateFee, calculateTotalFee, calculateTotalMovimentSystem } from "../../../utils/closureEvent";
import { TYPE_OF_CHARGE } from "../../../types/charge";

const TransferTable = ({ establishment, resume, eventGeral, closureResourcesEvent, faturamento, movimentacao, equipaments, typeOfChargeApplied }) => {


  const pos = useMemo(() => {
    if (!equipaments) return {};
    const posObj = equipaments.find((e) => e.key === 'pos');
    return posObj;
  }, [equipaments]);


  const rowsBankData = useMemo(() =>
  ([
    {
      bank: establishment?.bankAccount?.bank.toUpperCase(),
      bankCode: establishment?.bankAccount?.bankCode,
      agency: establishment?.bankAccount?.agency,
      account: establishment?.bankAccount?.account,
      pix: establishment?.bankAccount?.pix,
    },
  ]), [establishment])
  const columnsBankData = useMemo(() => ([
    {
      label: 'BANCO',
      field: 'bank'
    },
    {
      label: 'CODIGO DO BANCO',
      field: 'bankCode',
    },
    {
      label: 'AGÊNCIA',
      field: 'agency',
    },
    {
      label: 'CONTA CORRENTE',
      field: 'account',
    },
    {
      label: 'PIX',
      field: 'pix',
    }
  ]), [])
  if (!faturamento || !movimentacao || !eventGeral || !closureResourcesEvent || !resume || !establishment) return null;
  const totalMovimentacaoSistema = calculateTotalMovimentSystem(faturamento, movimentacao);
  const totalTaxaUget = calculateTotalFee(faturamento, movimentacao, eventGeral, closureResourcesEvent);
  const taxasResultados = calculateFee(faturamento, movimentacao, eventGeral, closureResourcesEvent);

  
  return (
    <>
      <div className="mt-4 pagebreak"  >
        <h4 className='text-center p-4 fw-bold mb-5'>FECHAMENTO uGet ;-) </h4>
        <table className='table table-striped table-fs-litle'>
          <tbody>

            {typeOfChargeApplied === TYPE_OF_CHARGE.RENT_CHARGE && pos?.total_rent > 0 && <tr>
              <td>VALORES REFERENTES A POS EM COMODATO</td>
              <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(pos?.total_rent)}</td>
            </tr>}
            {typeOfChargeApplied !== TYPE_OF_CHARGE.RENT_CHARGE &&
              <tr>
                <td>TOTAL REFERENTE AS TAXAS E LICENÇA DO SISTEMA uGet ;-) </td>
                <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(typeOfChargeApplied === TYPE_OF_CHARGE.TOTAL_CHARGE ? totalTaxaUget + pos?.total_rent : totalTaxaUget)}</td>
              </tr>
            }
            <tr>
              <td>TOTAL REFERENTE AOS SERVIÇOS E ADICIONAIS CONTRATADOS </td>
              <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.services_provided)}</td>
            </tr>
            {resume.losses_and_damages > 0 && <tr>
              <td>TOTAL REFERENTE A PERDAS E DANOS DE EQUIPAMENTOS</td>
              <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.losses_and_damages)}</td>
            </tr>}
            <tr>
              <td className="fw-bold">TOTAL uGet ;-) </td>
              <td className="text-tb-align-right fw-bold"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume?.total_uget_gross)}</td>
            </tr>
          </tbody>
        </table>
        <div className=''>
          <h4 className='text-center p-4 fw-bold mb-5'>FECHAMENTO GERAL</h4>
          <table className='table table-striped table-fs-litle'>
            <tbody>
              <tr>
                <td>TOTAL INTERMEDIADO PELO SISTEMA uGet ;-) DÉBITO + CRÉDITO + PIX</td>
                <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.total_intermediated)}</td>
              </tr>
              <tr>
                <td>VALOR TOTAL uGet ;-) </td>
                <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.total_uget_gross)}</td>
              </tr>
              <tr className="fw-bold">
                <td>VALOR DO REPASSE JÁ COM DESCONTO DO VALOR uGet ;-) </td>
                <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.full_transfer)}</td>
              </tr>
              <tr>
                <td>VALOR DA RETENÇÃO IRRF (1,5%)(ATENÇÃO - ESSE VALOR DE IMPOSTO FEDERAL ESTA SENDO REPASSADO PELA uGet ;-) E DEVERÁ SER RECOLHIDO PELO CONTRATANTE  - DUVIDAS CONSULTE O SEU CONTADOR / **RETENÇÃO DISPENSADA CASO VALOR ESTEJA  ABAIXO DO MÍNIMO**.)</td>
                <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.tax_irrf)}</td>
              </tr>

              <tr className="fw-bold">
                <td>VALOR DO REPASSE REFERENTE AO VALOR INTERMEDIADO SOMADO A RETENÇÃO DE IRRF</td>
                <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.full_transfer + (resume.tax_irrf  < 10 ? 0 : resume.tax_irrf))}</td>
              </tr>
              <tr className="fw-bold">
                <td>VALOR LÍQUIDO DISPONÍVEL UGET ;-)</td>
                <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.total_liquid + (resume.tax_irrf  < 10 ? resume.tax_irrf : 0))}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='d-flex flex-column col-xxl-12 justify-content-around p-4 mt-4 pagebreak '>
        <h4 className=' p-4 fw-bold mb-5'>DADOS PARA O REPASSE </h4>
        <h6> * O Repasse será realizado na conta corrente indicada pelo CLIENTE.</h6>
        <TableReport
          rows={rowsBankData}
          columns={columnsBankData}
          keyExtractor={(_, index) => index.toString()}
        />
      </div>
      
    </>
    
  );
}

export default TransferTable