import React, { useState, useEffect, memo, useCallback, useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Textarea from '../../bootstrap/forms/Textarea';
import Select from '../../bootstrap/forms/Select';
import masks, { unmask } from '../../../helpers/utils/masks';
import Wizard, { WizardItem } from '../../Wizard';
import Avatar from '../../MyCustom/Avatar';
import Button from '../../bootstrap/Button';
import Popovers from '../../bootstrap/Popovers';
// DataBase
import ProductDB from '../../../database/wrappers/product';
import CategoryComplementDB from '../../../database/wrappers/categoryComplement';
import Money from '../../../helpers/utils/money/money';
import { orderBy, where } from 'firebase/firestore';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import { convertTrybuIntToPTBR, TRIBUTARY_SITUATION } from '../../../types/fiscal';
import ListProductsSuggestion from '../../MyCustom/ListProductsSuggestion';
import SuggestionDB from '../../../database/wrappers/suggestion';
import ProductsAutoFill from '../ProductsAutoFill/ProductsAutoFill';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status do produto'
  }

  const price = unmask(values.price);
  // if (!values.price) {
  //   errors.price = 'Este campo é necessário';
  // } else if (isNaN(Number(price)) || Number(price) <= 0) {
  //   errors.price = 'O preço precisa ser um número válido e positivo';
  // }

  if (!values.unit) {
    errors.unit = 'Este campo é necessário';
  }

  if (!values.classification) {
    errors.classification = 'Este campo é necessário';
  }

  // Informações de Produto Composto
  // if (values.type_compound.includes('check')) {
  //   if (isNaN(Number(values.product_qtd)) || Number(values.product_qtd) < 0) {
  //     errors.product_qtd = 'A quantidade precisa ser um número válido';
  //   }

  //   if (Number(values.product_unit_value) < 0) {
  //     errors.product_unit_value = 'O valor precisa ser um número válido';
  //   }
  // }

  // Informaçoes Fiscais
  if (!values.fiscal_data_bar_code) {
    errors.fiscal_data_bar_code = 'Este campo é necessário';
  }
  if (!values.fiscal_data_ncm) {
    errors.fiscal_data_ncm = 'Este campo é necessário';
  }
  if (!values.fiscal_data_cst) {
    errors.fiscal_data_cst = 'Este campo é necessário';
  }
  // if (!values.fiscal_data_csosn) {
  //   errors.fiscal_data_csosn = 'Este campo é necessário';
  // }
  // if (!values.fiscal_data_cest) {
  //   errors.fiscal_data_cest = 'Este campo é necessário';
  // }
  if (!values.fiscal_data_cfop) {
    errors.fiscal_data_cfop = 'Este campo é necessário';
  }
  // if (!values.fiscal_data_aliq_icms) {
  //   errors.fiscal_data_aliq_icms = 'Este campo é necessário';
  // }
  if (!Object.keys(TRIBUTARY_SITUATION).find(key => TRIBUTARY_SITUATION[key] == values.fiscal_data_tributary_situation)) {
    errors.fiscal_data_tributary_situation = 'Selecione uma opção necessário';
  }

  return errors;
}

const _selectUnit = [
  { value: 'KG', text: 'KG' },
  { value: 'UN', text: 'UN' },
  { value: 'L', text: 'L' },
  { value: 'ML', text: 'ML' },
  { value: 'G', text: 'G' },
  { value: 'PC', text: 'PC' },
]

const _selectClassification = [
  { value: 'VEGE', text: 'VEGE' },
  { value: 'VEGA', text: 'VEGA' },
  { value: 'ORG', text: 'ORG' },
  { value: 'ACU', text: 'ACU' },
  { value: 'ZER', text: 'ZER' },
]

const ModalRegisterProducts = ({ open, setIsOpen, setIsClose, productSelected }) => {

  console.log(productSelected);
  const { establishment, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [productsResponse, setProductsResponse] = useState([]);
  const [categoryComplementsResponse, setCategoryComplementsResponse] = useState([]);
  // Array abaixo é utilizado para armazenar os dados do produto que está compondo o combo quando type_compound for check
  const [productsToCompound, setProductsToCompound] = useState([])
  const [suggestions, setsuggestions] = useState([])

  useEffect(() => {
    if (!open) {
      setProductsToCompound([])
      return formik.resetForm();
    }
    if (productSelected) {
      const {
        active,
        category_complements,
        suggestionId,
        classification,
        complements,
        eat_here,
        eat_out,
        fiscal_data,
        highlights,
        supplies,
        customizedPrice,
        idPrintItemsCombo,
        image_url,
        name,
        description,
        price,
        products,
        type_compound,
        unit,
        isComboPriceBased
      } = productSelected;

      console.log(products);

      setProductsToCompound(products)

      formik.setValues({
        image_url: image_url,
        name: name,
        description,
        active: active ? 'ativo' : 'inativo',
        price: Money.centsToCoin(price).toFixed(2),
        unit: unit,
        type_compound: type_compound ? ['check'] : [],
        category_complements: category_complements ? ['check'] : [],
        highlights: highlights ? ['check'] : [],
        supplies: supplies ? ['check'] : [],
        customizedPrice: customizedPrice ? ['check'] : [],
        idPrintItemsCombo: idPrintItemsCombo ? ['check'] : [],
        isComboPriceBased: isComboPriceBased ? ['check'] : [],
        suggestionId: suggestionId || 'x',
        classification: classification,
        eat_here: eat_here,
        eat_out: eat_out,

        complements: complements,

        // fiscal data
        fiscal_data_bar_code: fiscal_data?.bar_code,
        fiscal_data_ncm: fiscal_data?.ncm,
        fiscal_data_cst: fiscal_data?.cst,
        fiscal_data_csosn: fiscal_data?.csosn,
        fiscal_data_cest: fiscal_data?.cest,
        fiscal_data_cfop: fiscal_data?.cfop,
        // fiscal_data_aliq_icms: fiscal_data?.aliq_icms,
        fiscal_data_tributary_situation: fiscal_data?.tributary_situation,

        // propriedades acima: antes de enviar para o backend montar um objeto chamado fiscal_data
      });
    }
  }, [productSelected, open])

  // Pega os produtos
  useEffect(() => {
    if (establishment) {
      function sort(a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }
      async function getCategoriesComplements() {
        const categoryComplements = await new CategoryComplementDB(establishment.id).getAll(where('active', '==', true))
        categoryComplements.sort(sort);
        setCategoryComplementsResponse(categoryComplements)
      }
      async function getSuggestions() {
        const suggestions = await new SuggestionDB(establishment.id).getAll(where('active', '==', true))
        suggestions.sort(sort);
        setsuggestions(suggestions)
      }

      getCategoriesComplements();
      getSuggestions();
      return new ProductDB(establishment.id).on(products => {
        products.sort(sort)
        setProductsResponse(products)
      }, where('active', '==', true))
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      id: '',
      image: null,
      image_url: null,
      name: '',
      active: '',
      price: '',
      unit: '',
      description: '',
      type_compound: '',
      category_complements: '',
      suggestions: null,
      suggestionId: 'x',
      highlights: '',
      supplies: '',
      customizedPrice: '',
      classification: '',
      idPrintItemsCombo: '',
      isComboPriceBased: '',
      eat_here: false,
      eat_out: false,

      // products
      product_qtd: '',
      product_unit: '',
      product_unit_value: '',
      product: '',
      // propriedades acima: antes de enviar para o backend montar um array de objetos

      complements: [], //Categoria de Complementos
      productsAux: {},


      // fiscal data
      fiscal_data_bar_code: '',
      fiscal_data_ncm: '',
      fiscal_data_cst: '',
      fiscal_data_csosn: '',
      fiscal_data_cest: '',
      fiscal_data_cfop: '',
      // fiscal_data_aliq_icms: '',
      fiscal_data_tributary_situation: '',
      // propriedades acima: antes de enviar para o backend montar um objeto chamado fiscal_data

    },
    validate: validate,
    onSubmit: values => {
      const {
        name,
        active,
        price,
        unit,
        type_compound,
        category_complements,
        suggestionId,
        image,
        highlights,
        supplies,
        customizedPrice,
        idPrintItemsCombo,
        classification,
        eat_here,
        eat_out,
        description,
        complements,

        // fiscal data
        fiscal_data_bar_code,
        fiscal_data_ncm,
        fiscal_data_cst,
        fiscal_data_csosn,
        fiscal_data_cest,
        fiscal_data_cfop,
        // fiscal_data_aliq_icms,
        fiscal_data_tributary_situation,
        // propriedades acima: antes de enviar para o backend montar um objeto chamado fiscal_data
        isComboPriceBased
      } = values

      const price_unmasked = unmask(price)

      console.log(productsToCompound);
      const products = productsToCompound ? productsToCompound.map(item => ({
        name: item.name || productsNoCombo.find(p => p.id === item.product)?.name || '',
        qtd: Number(item.qtd),
        unit: item.unit,
        unit_value: Number(unmask(item.unit_value.toString())),
        product: item.product
      })) : [];

      const fiscal_data = {
        bar_code: fiscal_data_bar_code,
        ncm: fiscal_data_ncm,
        cst: fiscal_data_cst,
        csosn: fiscal_data_csosn,
        cest: fiscal_data_cest,
        cfop: fiscal_data_cfop,
        // aliq_icms: fiscal_data_aliq_icms,
        tributary_situation: fiscal_data_tributary_situation,
      };

      const data = {
        image,
        name,
        description,
        active: active === 'ativo',
        price: Number(price_unmasked),
        unit,
        type_compound: type_compound.includes('check'),
        category_complements: category_complements.includes('check'),
        suggestionId: suggestionId === 'x' ? null : suggestionId,
        highlights: highlights.includes('check'),
        supplies: supplies.includes('check'),
        customizedPrice: customizedPrice.includes('check'),
        idPrintItemsCombo: idPrintItemsCombo.includes('check') && type_compound.includes('check') && products.length > 0,
        classification: classification,
        eat_here,
        eat_out,
        products,
        complements: complements || [],
        fiscal_data,
        isComboPriceBased: isComboPriceBased.includes('check') && type_compound.includes('check') && products.length > 0,
      }

      console.log(data);

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true)
      if (productSelected) {
        new ProductDB(establishment.id)
          .update(productSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new ProductDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  });

  const checkStepIsValid = (step) => {
    if (step === 'step1') {
      if (
        formik.values.name !== '' &&
        formik.values.active !== '' &&
        formik.values.price !== '' &&
        formik.values.unit !== '' &&
        formik.values.classification !== ''
      ) {
        return false;
      } else {
        return true;
      }
    }
    if (step === 'add_product') {
      if (
        formik.values.product_qtd !== '' &&
        formik.values.product_unit !== '' &&
        formik.values.product_unit_value !== '' &&
        formik.values.product !== ''
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  const productDicionary = useMemo(() => {
    const productDicionary = {}
    productsResponse.forEach(item => productDicionary[item.id] = item)
    return productDicionary;
  }, [productsResponse])
  const productsNoCombo = useMemo(() => productsResponse.filter(prod => prod.type_compound === false), [productsResponse])
  const productsNoComboOption = useMemo(() => productsNoCombo.map(item => ({ value: item.id, text: item.name })), [productsNoCombo])


  const handleAddProductsToCompound = () => {
    if (
      !formik.values.product_qtd ||
      isNaN(Number(formik.values.product_qtd)) ||
      !formik.values.product_unit ||
      !formik.values.product_unit_value ||
      !formik.values.product
    ) return;
    setProductsToCompound([
      ...productsToCompound,
      {
        name: productsNoComboOption.find(item => item.value === formik.values.product)?.text || '',
        qtd: formik.values.product_qtd,
        unit: formik.values.product_unit,
        unit_value: unmask(formik.values.product_unit_value),
        product: formik.values.product,
      }
    ])
    formik.setFieldValue('product_qtd', '')
    formik.setFieldValue('product_unit', '')
    formik.setFieldValue('product_unit_value', '')
    formik.setFieldValue('product', '')
  }

  const handleRemoveProductsToCompound = (index) => {
    const newProductsToCompound = productsToCompound.filter((item, i) => i !== index)
    setProductsToCompound(newProductsToCompound)
  }

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const HeaderComplements = useMemo(() => (
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <p>#</p>
      </div>
      <div className='col-md-3'>
        <p>Nome</p>
      </div>
      <div className='col-md-4'>
        <p>Descrição</p>
      </div>
      <div className='col-md-2'>
        <p>Mínimo</p>
      </div>
      <div className='col-md-2'>
        <p>Maxímo</p>
      </div>
    </div>
  ), [])

  const optionsSituation = useMemo(() => Object.keys(TRIBUTARY_SITUATION).map(key => ({ value: TRIBUTARY_SITUATION[key], text: convertTrybuIntToPTBR(TRIBUTARY_SITUATION[key]) })), [])
  const optionsSuggestion = useMemo(() => {
    const options = suggestions.map(item => ({ value: item.id, text: item.name }))
    options.unshift({ value: 'x', text: 'Nenhuma' })
    return options;
  }, [suggestions])



  async function getImageFileFromUrl(imageUrl) {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    const filename = imageUrl.split('/').pop()
    return new File([blob], filename)
  }

  function onChosenProduct(product) {
    async function updateFormik() {
      await formik.setFieldValue('name', product.name)
      await formik.setFieldValue('unit', product.unit)
      formik.setFieldTouched('name', true, true)
      formik.setFieldTouched('unit', true, true)

      const imageFile = await getImageFileFromUrl(product.image)
      await formik.setFieldValue('image_url', product.image)
      await formik.setFieldValue('image', imageFile)
    }

    updateFormik()
  }


  return (
    <Modal
      id={'modal-register-products'}
      titleId={'Cadastro de Produtos'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={true}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-procuts">{productSelected ? 'Atualização de Produtos' : 'Cadastro de Produtos'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Wizard
          isHeader
          color='info'
          onSubmit={formik.handleSubmit}
          className='shadow-3d-info'
          isLoading={isLoading}
        >
          <WizardItem id='step1' title='Informações do Produto' required={checkStepIsValid('step1')}>
            {/* Imagem do Produto */}
            <div className='row align-items-center justify-content-center'>
              <FormGroup
                id='image_url'
                ariaLabel='Logo'
              >
                <Avatar
                  id='image_url'
                  value={formik.values.image_url}
                  onChange={handleImage} />
              </FormGroup>
            </div>
            {/* Dados */}
            < div className="row g-4 mt-4">
              {/* Nome */}

              <FormGroup id="name" label="Nome" className='col-md-6'>
                <Input
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isValid={formik.isValid}
                  isTouched={formik.touched.name}
                  invalidFeedback={formik.errors.name}
                  validFeedback='Assim está bom!'
                  placeholder='Copo de vidro, garrafa, etc.'
                />
              </FormGroup>
              <ProductsAutoFill searchQuery={formik.values.name} onChosenProduct={onChosenProduct} />

              {/* Preço */}
              <FormGroup id='price' label='Preço' className='col-md-6'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.money(formik.values.price)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.price}
                  invalidFeedback={formik.errors.price}
                  validFeedback='Assim está bom!'
                  placeholder='50,00'
                />
              </FormGroup>

              {/* Unit */}
              <FormGroup id='unit' label='Unidade' className='col-md-3'>
                <Select
                  placeholder='Selecione o tipo'
                  list={_selectUnit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.unit}
                  isValid={formik.isValid}
                  isTouched={formik.touched.unit}
                  invalidFeedback={formik.errors.unit}
                />
              </FormGroup>

              {/* Classificação */}
              <FormGroup id='classification' label='Classificação' className='col-md-3'>
                <Select
                  placeholder='Selecione o tipo'
                  list={_selectClassification}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.classification}
                  isValid={formik.isValid}
                  isTouched={formik.touched.classification}
                  invalidFeedback={formik.errors.classification}
                />
              </FormGroup>

              {/* Sugestão */}
              <FormGroup id='suggestionId' label='Sugestão (Opcional)' className='col-md-6'>
                <Select
                  placeholder='Selecione uma sugestão se desejar'
                  list={optionsSuggestion}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.suggestionId}
                  isValid={formik.isValid}
                  isTouched={formik.touched.suggestionId}
                  invalidFeedback={formik.errors.suggestionId}
                />
              </FormGroup>

              {/* Descrição */}
              <FormGroup id="description" label="Descrição" className='col-md-12'>
                <Textarea
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  isValid={formik.isValid}
                  isTouched={formik.touched.description}
                  invalidFeedback={formik.errors.description}
                  validFeedback='Assim está bom!'
                  placeholder='Informe, se desejar, uma descrição para o produto'
                />
              </FormGroup>

              <h6>Informações Adicionais</h6>

              {/* Type Compound */}
              <div className='col-md-3'>
                <div className='h-100 d-flex flex-column justify-content-center'>
                  <Label>Estoque Composto?</Label>
                  {productsNoCombo.length > 0 ? (
                    <ChecksGroup
                      isValid={formik.isValid}
                      isTouched={formik.touched.type_compound}
                      invalidFeedback={formik.errors.type_compound}>
                      <Checks
                        type="switch"
                        id="type_compound"
                        name="type_compound"
                        value='check'
                        onChange={formik.handleChange}
                        checked={formik.values.type_compound.includes('check')}
                        isInline
                      />
                    </ChecksGroup>
                  ) : (
                    <Popovers desc="Sem produtos cadastrados ainda" placement="bottom" trigger="hover">
                      <ChecksGroup>
                        <Checks
                          type="switch"
                          isInline
                          disabled={productsResponse.length === 0 ? true : false}
                        />
                      </ChecksGroup>
                    </Popovers>
                  )}
                </div>
              </div>

              {/* Category Complements */}
              <div className='col-md-3'>
                <div className='h-100 d-flex flex-column justify-content-center'>
                  <Label>Complementos?</Label>
                  {categoryComplementsResponse.length > 0 ? (
                    <ChecksGroup
                      isValid={formik.isValid}
                      isTouched={formik.touched.category_complements}
                      invalidFeedback={formik.errors.category_complements}>
                      <Checks
                        type="switch"
                        id="category_complements"
                        name="category_complements"
                        value='check'
                        onChange={formik.handleChange}
                        checked={formik.values.category_complements.includes('check')}
                        isInline
                      />
                    </ChecksGroup>
                  ) : (
                    <Popovers desc="Sem complementos cadastrados ainda" placement="bottom" trigger="hover">
                      <ChecksGroup>
                        <Checks
                          type="switch"
                          isInline
                          disabled={categoryComplementsResponse.length === 0 ? true : false}
                        />
                      </ChecksGroup>
                    </Popovers>
                  )}
                </div>
              </div>

              {/* Destaque */}
              <div className='col-md-3'>
                <div className='h-100 d-flex flex-column justify-content-center'>
                  <Label>Destaque?</Label>
                  <ChecksGroup
                    isValid={formik.isValid}
                    isTouched={formik.touched.highlights}
                    invalidFeedback={formik.errors.highlights}>
                    <Checks
                      type="switch"
                      id="highlights"
                      name="highlights"
                      value='check'
                      onChange={formik.handleChange}
                      checked={formik.values.highlights.includes('check')}
                      isInline
                    />
                  </ChecksGroup>
                </div>
              </div>

              {/* Insumo */}
              <div className='col-md-3'>
                <div className='h-100 d-flex flex-column justify-content-center'>
                  <Label>Insumo?</Label>
                  <ChecksGroup
                    isValid={formik.isValid}
                    isTouched={formik.touched.supplies}
                    invalidFeedback={formik.errors.supplies}>
                    <Checks
                      type="switch"
                      id="supplies"
                      name="supplies"
                      value='check'
                      onChange={formik.handleChange}
                      checked={formik.values.supplies.includes('check')}
                      isInline
                    />
                  </ChecksGroup>
                </div>
              </div>



                <div className='col-md-3'>
                  <div className='h-100 d-flex flex-column justify-content-center'>
                    <>
                      <Label>Preço personalizavel?</Label>
                      <ChecksGroup
                        isValid={formik.isValid}
                        isTouched={formik.touched.customizedPrice}
                        invalidFeedback={formik.errors.customizedPrice}>
                        <Checks
                          type="switch"
                          id="customizedPrice"
                          name="customizedPrice"
                          value='check'
                          onChange={formik.handleChange}
                          checked={formik.values.customizedPrice.includes('check')}
                          isInline
                        />
                      </ChecksGroup>
                    </>
                  </div>
                </div>
              
              
              {/* Comer no local? */}
              <FormGroup className='col-md-5'>
                <Label>Comer no local?</Label>
                <ChecksGroup isInline>
                  <Checks
                    type="checkbox"
                    id="eat_here"
                    label="Comer no local"
                    name="eat_here"
                    onChange={formik.handleChange}
                    checked={formik.values.eat_here}
                  />
                  <Checks
                    type="checkbox"
                    id="eat_out"
                    label="Comer fora"
                    name="eat_out"
                    onChange={formik.handleChange}
                    checked={formik.values.eat_out}
                  />
                </ChecksGroup>
              </FormGroup>

              {/* Status */}
              <FormGroup className='col-md-4'>
                <Label>Status</Label>
                <ChecksGroup
                  isValid={formik.isValid}
                  isTouched={formik.touched.active}
                  invalidFeedback={formik.errors.active}
                >
                  <Checks
                    type="radio"
                    id="active"
                    label="Ativo"
                    name="active"
                    value="ativo"
                    onChange={formik.handleChange}
                    checked={formik.values.active}
                    isInline
                  />
                  <Checks
                    type="radio"
                    id="active2"
                    label="Inativo"
                    name="active"
                    value="inativo"
                    onChange={formik.handleChange}
                    checked={formik.values.active}
                    isInline
                  />
                </ChecksGroup>
              </FormGroup>
            </div>
          </WizardItem>

          <WizardItem id='step2' title='Estoque Composto'>
            <div className="row g-4">
              {formik.values.type_compound.includes('check') ? (
                <>
                  <FormGroup className='col-md-12'>
                    <ChecksGroup isInline>
                      <Checks
                        type="switch"
                        id="idPrintItemsCombo"
                        name="idPrintItemsCombo"
                        label='Imprimir items do combo em tickets separados (APENAS NO TOTEM NO MOMENTO)'
                        value='check'
                        onChange={formik.handleChange}
                        checked={formik.values.idPrintItemsCombo.includes('check')}
                        isInline
                      />
                    </ChecksGroup>

                    <ChecksGroup isInline>
                      <Checks
                        type="switch"
                        id="isComboPriceBased"
                        name="isComboPriceBased"
                        label='Basear preço do combo na soma total dos produtos'
                        value='check'
                        onChange={formik.handleChange}
                        checked={formik.values.isComboPriceBased.includes('check')}
                        isInline
                      />
                    </ChecksGroup>
                  </FormGroup>
                  <div className='col-md-11'>
                    <div className='d-flex'>
                      {/* Produto */}
                      <FormGroup id='product' label='Produto' className='col-md-4 px-2'>
                        <Select
                          placeholder='Selecione o produto'
                          list={productsNoComboOption}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.product}
                        // isValid={formik.isValid}
                        // isTouched={formik.touched.product}
                        // invalidFeedback={formik.errors.product}
                        />
                      </FormGroup>

                      {/* Quantidade Disponível */}
                      <FormGroup id='product_qtd' label='Quantidade' className='col-md-2 px-2'>
                        <Input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.product_qtd?.replace(/\D/g, "")}
                          isValid={formik.isValid}
                          isTouched={formik.touched.product_qtd}
                          invalidFeedback={formik.errors.product_qtd}
                          validFeedback='Assim está bom!'
                          placeholder='100'
                        />
                      </FormGroup>

                      {/* Unit */}
                      <FormGroup id='product_unit' label='Unidade' className='col-md-4 px-2'>
                        <Select
                          placeholder='Selecione o tipo'
                          list={_selectUnit}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.product_unit}
                        // isValid={formik.isValid}
                        // isTouched={formik.touched.product_unit}
                        // invalidFeedback={formik.errors.product_unit}
                        />
                      </FormGroup>

                      {/* Valor por unidade */}
                      <FormGroup id='product_unit_value' label='Valor' className='col-md-2 px-2'>
                        <Input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={masks.money(formik.values.product_unit_value)}
                          // isValid={formik.isValid}
                          // isTouched={formik.touched.product_unit_value}
                          // invalidFeedback={formik.errors.product_unit_value}
                          // validFeedback='Assim está bom!'
                          placeholder='100'
                        />
                      </FormGroup>
                    </div>
                  </div>
                  {/* Botão de adicionar produto ao combo */}
                  <div className='col-md-1'>
                    {checkStepIsValid('add_product') ? (
                      <Popovers desc="Por favor, preencha os campos" placement="right" trigger="hover">
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                          {/* // Não apagar o espaço entre as labels */}
                          <Label>⠀⠀⠀⠀⠀⠀⠀⠀⠀</Label>
                          <Button
                            disabled={checkStepIsValid('add_product')}
                            color="light"
                            icon="PlusCircle"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                            onClick={handleAddProductsToCompound}
                          />
                        </div>
                      </Popovers>
                    ) : (
                      <div className='d-flex flex-column justify-content-center align-items-center'>
                        {/* // Não apagar o espaço entre as labels */}
                        <Label>⠀⠀⠀⠀⠀⠀⠀⠀⠀</Label>
                        <Button
                          disabled={checkStepIsValid('add_product')}
                          color="light"
                          icon="PlusCircle"
                          shadow="sm"
                          hoverShadow="sm"
                          size="sm"
                          onClick={handleAddProductsToCompound}
                        />
                      </div>
                    )}
                  </div>
                  {/* Produtos adicionados */}
                  <div className="col-md-12">
                    {productsToCompound.length > 0 && (
                      <h6>Produtos adicionados</h6>
                    )}
                    {productsToCompound.map((product, index) => (
                      <div className='d-flex justify-content-center align-items-center py-1' key={index}>
                        <div className='d-flex'>
                          <FormGroup className='col-md-4 px-2'>
                            <Input
                              value={productDicionary[product.product]?.name}
                              disabled
                              readOnly
                            />
                          </FormGroup>

                          <FormGroup className='col-md-2 px-2'>
                            <Input
                              value={product.qtd}
                              disabled
                              readOnly
                            />
                          </FormGroup>

                          <FormGroup className='col-md-2 px-2'>
                            <Input
                              value={product.unit}
                              disabled
                              readOnly
                            />
                          </FormGroup>

                          <FormGroup className='col-md-2 px-2'>
                            <Input
                              value={Money.centsToMaskMoney(product.unit_value)}
                              disabled
                              readOnly
                            />
                          </FormGroup>
                        </div>
                        <div className='d-flex justify-content-center align-items-center px-2'>
                          <Button
                            color="danger"
                            icon="Trash"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                            onClick={() => handleRemoveProductsToCompound(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                </>
              ) : (
                <div className='row g-4 mt-1'>
                  <h6 className='text-center'>Você deve marcar a opção <b className='text-primary'>"Estoque Composto?"</b> em <b className='text-primary'>"Informações do Produto"</b> para visualizar esta seção.</h6>
                </div>
              )}
            </div>
          </WizardItem>

          <WizardItem id='step3' title='Categorias de complementos'>
            <div className="row g-4">
              {formik.values.category_complements.includes('check') ? (
                categoryComplementsResponse.length > 0 ? (
                  <>
                    {HeaderComplements}
                    {categoryComplementsResponse.map((categoryComplement, index) => {
                      return (
                        <div className="row g-4 py-2" key={index} style={{ marginTop: 0 }}>
                          <div className='col-md-1' style={{ marginTop: 0 }}>
                            <div className='h-100 d-flex align-items-center justify-content-center' style={{ marginTop: 3, marginRight: 0 }}>
                              <ChecksGroup style={{ marginRight: 0 }}>
                                <Checks
                                  type="switch"
                                  id="complements"
                                  name="complements"
                                  value={categoryComplement.id}
                                  onChange={formik.handleChange}
                                  checked={formik.values.complements?.includes(categoryComplement.id)}
                                  isInline
                                />
                              </ChecksGroup>
                            </div>
                          </div>
                          <div className='col-md-3' style={{ marginTop: 0 }}>
                            <FormGroup>
                              <Input
                                value={categoryComplement.name}
                                disabled
                                readOnly
                              />
                            </FormGroup>
                          </div>
                          <div className='col-md-4' style={{ marginTop: 0 }}>
                            <FormGroup>
                              <Input
                                value={categoryComplement.description}
                                disabled
                                readOnly
                              />
                            </FormGroup>
                          </div>
                          <div className='col-md-2' style={{ marginTop: 0 }}>
                            <FormGroup>
                              <Input
                                value={categoryComplement.min_item?.toString()}
                                disabled
                                readOnly
                              />
                            </FormGroup>
                          </div>
                          <div className='col-md-2' style={{ marginTop: 0 }}>
                            <FormGroup>
                              <Input
                                value={categoryComplement.max_item?.toString()}
                                disabled
                                readOnly
                              />
                            </FormGroup>
                          </div>
                        </div>
                      )
                    })}
                  </>
                ) : (
                  <div className='row g-4 mt-1'>
                    <h6 className='text-center'>Não há complementos cadastrados para este estabelecimento</h6>
                  </div>
                )
              ) : (
                <div className='row g-4 mt-1'>
                  <h6 className='text-center'>Você deve marcar a opção <b className='text-primary'>"Complementos?"</b> em <b className='text-primary'>"Informações do Produto"</b> para visualizar esta seção.</h6>
                </div>
              )}
            </div>
          </WizardItem>

          <WizardItem id='step5' title='Informações Fiscais'>
            <div className="row g-4">
              {/* Bar Code */}
              <FormGroup id='fiscal_data_bar_code' label='Código de Barras' className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_bar_code}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_bar_code}
                  invalidFeedback={formik.errors.fiscal_data_bar_code}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'

                />
              </FormGroup>

              {/* NCM */}
              <FormGroup id='fiscal_data_ncm' label='NCM' className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_ncm}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_ncm}
                  invalidFeedback={formik.errors.fiscal_data_ncm}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'

                />
              </FormGroup>

              {/* CST */}
              <FormGroup id='fiscal_data_cst' label='CST / ICMS' className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cst}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cst}
                  invalidFeedback={formik.errors.fiscal_data_cst}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'

                />
              </FormGroup>

              {/* CSOSN */}
              <FormGroup id='fiscal_data_csosn' label='CSOSN' className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_csosn}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_csosn}
                  invalidFeedback={formik.errors.fiscal_data_csosn}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              {/* CEST */}
              <FormGroup id='fiscal_data_cest' label='CEST' className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cest}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cest}
                  invalidFeedback={formik.errors.fiscal_data_cest}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              {/* CFOF */}
              <FormGroup id='fiscal_data_cfop' label='CFOP' className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cfop}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cfop}
                  invalidFeedback={formik.errors.fiscal_data_cfop}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              {/* Aliquota ICMS */}
              {/* <FormGroup id='fiscal_data_aliq_icms' label='Alíquota ICMS' className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_aliq_icms}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_aliq_icms}
                  invalidFeedback={formik.errors.fiscal_data_aliq_icms}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup> */}

              {/* Situação Tributária */}
              <FormGroup id='fiscal_data_tributary_situation' label='Situação Tributária' className='col-md-4'>
                <Select
                  placeholder='Selecione'
                  value={formik.values.fiscal_data_tributary_situation}
                  list={optionsSituation}
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_tributary_situation}
                  invalidFeedback={formik.errors.fiscal_data_tributary_situation}
                />
              </FormGroup>
            </div>
          </WizardItem>
        </Wizard>
      </ModalBody>
    </Modal >
  )
}

export default memo(ModalRegisterProducts);