import React, { useState, useMemo, useContext, memo, useRef, useEffect } from 'react';
import Modal, { ModalBody, ModalFooter } from '../../bootstrap/Modal';
import { DefaultContext } from '../../../contexts/default';
import Button from '../../bootstrap/Button';
import { useReactToPrint } from 'react-to-print';
import PreAlert from '../../../helpers/utils/preAlert';
import EventGeralDB from '../../../database/wrappers/eventGeral';
import Spinner from '../../bootstrap/Spinner';
import useDarkMode from '../../../hooks/useDarkMode';
import BtnCancel from '../../MyCustom/BtnCancel';
import Logo from "../../Logo";
import dateFormat from "dateformat";
import TableReport from "../../MyCustom/TableReport";
import { mountEventGeralTable } from '../../../utils/os/osTable';

import './styles.css'

const RelatorioOs = ({ open, setIsOpen, setIsClose, eventSelected }) => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, onShowAlert } = useContext(DefaultContext);

  const [loading, setloading] = useState(false)
  const [eventGeral, seteventGeral] = useState(null)

  useEffect(() => {
    if (open && !!eventSelected) {
      async function loadData() {
        try {
          setloading(true);
          const data = await new EventGeralDB(estabSelected).get(eventSelected.id);
          seteventGeral(data)
        } catch (error) {
          console.error(error);
          setIsOpen(false)
          onShowAlert(PreAlert.error('Falhou ao importar os dados do evento'))
        } finally {
          setloading(false)
        }
      }

      loadData();
    }
  }, [open, eventSelected])

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Relatorio O.S. - ' + eventSelected?.name,
    // onAfterPrint: () => setIsOpen(false),
  });

  const LoadSpinner = () => (
    <div className='d-flex align-items-center justify-content-center h-100'>
      <Spinner
        size={80}
        color={darkModeStatus ? 'light' : 'dark'}
      />
    </div>
  )

  const column = useMemo(() => ([
    {
      label: 'Nº',
      field: 'number',
    },
    {
      label: 'Equipamento',
      field: 'equipment',
    },
    {
      label: 'Qtde',
      field: 'quantity',
    }
  ]), [])

  const column2 = useMemo(() => ([
    {
      label: 'Nº',
      field: 'number',
    },
    {
      label: 'Equipamento',
      field: 'equipment',
    },
    {
      label: 'Qtde',
      field: 'quantity2',
    }
  ]), [])

  const rowsRender = useMemo(() => {
    if(!eventGeral || !eventGeral.resources) return [];
    return mountEventGeralTable(eventGeral);
  },[eventGeral])

  return (
    <Modal
      id={'modal-register-stores'}
      titleId={'Cadastro de Estabelecimentos'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="xl" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      fullScreen
    // onSubmit={formik.handleSubmit}
    >
      <ModalBody className='px-5 pt-5' ref={componentRef} >
        <div className='row justify-content-between'>
          <div className='col p-0 px-4'>
            <Logo width={200} black />
          </div>
          <div className='row px-4 mt-4'>
            <div className='col-8'>
              <h6 className='my-3'>EVENTO: {eventSelected?.name}</h6>
            </div>
            <div className='col-4'>
              <h6 className='my-3 text-end'>DATA EVENTO: {dateFormat(eventSelected?.date.toDate(), 'dd/mm/yyyy')}</h6>
            </div>
            <div className='col-8'>
              <h6 >TÉCNICOS: </h6>
            </div>
            <div className='col-4'>
              <h6 className=' text-end'>DATA SAIDA: {dateFormat(eventSelected?.date.toDate(), 'dd/mm/yyyy')}</h6>
            </div>
          </div>
        </div>
        {loading ?
          <LoadSpinner />
          :
          <>
            <div className='row my-2 p-4'>
              <div className='col-6 PX-4'>
                <TableReport
                  title='SAÍDA'
                  rows={rowsRender}
                  columns={column}
                  keyExtractor={row => row.id}
                />
              </div>
              <div className='col-6'>
                <TableReport
                  title='RETORNO'
                  rows={rowsRender}
                  columns={column2}
                  keyExtractor={row => row.id}
                />
              </div>
            </div>
            <div className='col mt-5 p-4'>
              <hr className='m-0 mt-4'></hr>
              <p className='text-center'>Assinatura técnico</p>
            </div>
            
            <div className='col mt-5 p-4' >
              <hr className='m-0 mt-4'></hr>
              <p className='text-center'>Assinatura estoque</p>
            </div>
            <div className='col mt-5 p-4'>
              <hr className='m-0 mt-4'></hr>
              <p className='text-center'>Assinatura cliente</p>
            </div>
            {/* <div className='col my-5 pt-5'>
              <Textarea
                placeholder='Informe, se desejar, uma descrição para o produto'
              />
            </div> */}
          </>
        }
      </ModalBody>

      <ModalFooter>
        <div className='row col-md-12 justify-content-around'>
          <BtnCancel
            className='col-md-3'
            setIsClose={setIsClose}
          />
          <Button onClick={handlePrint} className='col-md-3' type='button' color='primary' icon="PictureAsPdf" rounded={1} hoverShadow="sm" shadow="sm" size="sm">
            Imprimir ou Baixar
          </Button>
        </div>
      </ModalFooter>
    </Modal >
  )
}

export default memo(RelatorioOs);