import React, { useEffect, useMemo } from "react";
import Money from "../../../helpers/utils/money/money";
import { TYPE_OF_CHARGE } from "../../../types/charge";


const AdditionalServices = ({ typeOfChargeApplied, equipaments, closureResourcesEvent, custoOperacional, responsibles, technical, resume, eventGeral }) => {


  const [totems, totems_recharges, cartoes,cards_adm
  ] = useMemo(() => {
    if (!equipaments) return [{}, {}, {}];
    return [
      equipaments.find(r => r.key === 'totems'),
      equipaments.find(r => r.key === 'totems_recharges'),
      equipaments.find(r => r.key === 'cards'),
      equipaments.find(r => r.key === 'cards_adm'),

    ]
  }, [equipaments])

  if (!responsibles || !technical || !equipaments) return null;

  const qtdDiaria = closureResourcesEvent.technical.daily;
  const technicalCount = technical.length;
  const liderCount = responsibles.length;



  return (
    <div className="" >
      <h4 className='text-center p-4 fw-bold mb-5'>VALORES REFERENTE AOS SERVIÇOS E ADICIONAIS </h4>
      <table className='table table-striped table-fs-litle'>
        <tbody>


          {(cartoes?.qtdSent * cartoes?.total > 0) && <tr>
            <td colSpan='2'>TOTAL DE CARTÕES DE CONSUMO CONSIGNADOS </td>
            <td colSpan='2'>CARTÕES ENVIADOS : {cartoes?.qtdSent}</td>
            <td className="text-tb-align-right" colSpan='2'>CARTÕES DEVOLVIDOS: {cartoes?.qtdReceived}</td>
            <td className="text-tb-align-right" colSpan='2'>CARTÕES NÃO DEVOLVIDOS: {cartoes?.qtd_loss}</td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(cartoes?.total_loss)}</td>
          </tr>}
          {(cards_adm?.qtdSent * cards_adm?.total > 0) && <tr>
            <td colSpan='2'>TOTAL DE CARTÕES DE ADMINISTRADOR </td>
            <td colSpan='2'>CARTÕES ENVIADOS : {cards_adm?.qtdSent}</td>
            <td className="text-tb-align-right" colSpan='2'>CARTÕES DEVOLVIDOS: {cards_adm?.qtdReceived}</td>
            <td className="text-tb-align-right" colSpan='2'>CARTÕES NÃO DEVOLVIDOS: {cards_adm?.qtd_loss}</td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(cards_adm?.total_loss)}</td>
          </tr>}
          {(qtdDiaria * custoOperacional.daily.technical * eventGeral.resources.technical > 0) && <tr>
            <td colSpan='2'>TÉCNICOS uGet ;-) {qtdDiaria} DIÁRIA DE 10 HORAS CADA</td>
            <td colSpan='3'>QUANTIDADE DE TÉCNICOS: {eventGeral.resources.technical}</td>
            <td colSpan='3'>VALOR POR DIÁRIA:  {Money.centsToMaskMoney(custoOperacional.daily.technical)}</td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney((qtdDiaria * custoOperacional.daily.technical) * eventGeral.resources.technical)}</td>
          </tr>}
          {(closureResourcesEvent.technical.extra_hour * custoOperacional.hours.technical > 0) && <tr>
            <td colSpan='2'>HORA ADICIONAL TÉCNICOS uGet ;-) </td>
            <td colSpan='3'>QUANTIDADE DE HORAS: {closureResourcesEvent.technical.extra_hour}</td>
            <td colSpan='3'>VALOR POR HORA: {Money.centsToMaskMoney(custoOperacional.hours.technical)}</td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right"> {Money.centsToMaskMoney((closureResourcesEvent.technical.extra_hour * custoOperacional.hours.technical) * eventGeral.resources.technical)}</td>
          </tr>}
          {(qtdDiaria * custoOperacional.daily.technical * technicalCount > 0) && <tr>
            <td colSpan='2'>LÍDERES uGet ;-) {qtdDiaria} DIÁRIA DE 10 HORAS CADA</td>
            <td colSpan='3'>QUANTIDADE LÍDERES: {eventGeral.resources.coordinators}</td>
            <td colSpan='3'>VALOR POR DIÁRIA:  {Money.centsToMaskMoney(custoOperacional.daily.coordinator)}</td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney((qtdDiaria * custoOperacional.daily.coordinator) * eventGeral.resources.coordinators)}</td>
          </tr>}

          {(closureResourcesEvent.technical.extra_hour * custoOperacional.hours.coordinator > 0) && <tr>
            <td colSpan='2'>HORA ADICIONAL LÍDERES  uGet ;-) </td>
            <td colSpan='3'>QUANTIDADE DE HORAS: {closureResourcesEvent.technical.extra_hour}</td>
            <td colSpan='3'>VALOR POR HORA: {Money.centsToMaskMoney(custoOperacional.hours.coordinator)}</td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right"> {Money.centsToMaskMoney((closureResourcesEvent.technical.extra_hour * custoOperacional.hours.coordinator) * eventGeral.resources.coordinators)}</td>
          </tr>}
          {(closureResourcesEvent.technical.uber > 0) && <tr>
            <td colSpan='8'>VALOR REFERENTE AO TRANSPORTE </td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.technical.uber)}</td>
          </tr>}

          {(closureResourcesEvent.technical.food > 0) && <tr>
            <td colSpan='8'>VALOR REFERENTE A DIÁRIA DE ALIMENTAÇÃO  </td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right"> {Money.centsToMaskMoney(closureResourcesEvent.technical.food)}</td>
          </tr>}

          {(closureResourcesEvent.technical.ticket > 0) && <tr>
            <td colSpan='8'>VALOR REFERENTE A PASSAGEM </td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right"> {Money.centsToMaskMoney(closureResourcesEvent.technical.ticket)}</td>
          </tr>}

          {(closureResourcesEvent.technical.freight > 0) && <tr>
            <td colSpan='8'>VALOR REFERENTE A FRETE </td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right"> {Money.centsToMaskMoney(closureResourcesEvent.technical.freight)}</td>
          </tr>}


          {(closureResourcesEvent.technical.accommodation > 0) && <tr>
            <td colSpan='8'>VALOR REFERENTE A HOSPEDAGEM TÉCNICOS  </td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right"> {Money.centsToMaskMoney(closureResourcesEvent.technical.accommodation)}</td>
          </tr>}

          {(totems?.total !== undefined) && (totems?.total !== null) && <tr>
            <td colSpan='2'>TOTEM TICKET  </td>
            <td colSpan='3'>QUANTIDADE DE TOTEM TICKET: {totems?.qtdSent}</td>
            <td colSpan='3'>VALOR POR DIÁRIA: {Money.centsToMaskMoney(totems?.value)}</td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(totems?.qtdSent * totems?.value)}</td>
          </tr>}

          {(totems_recharges?.total !== undefined) && (totems_recharges?.total !== null) && <tr>
            <td colSpan='2'>TOTEM RECARGA </td>
            <td colSpan='3'>QUANTIDADE DE TOTENS RECARGA: {totems_recharges?.qtdSent}</td>
            <td colSpan='3'>VALOR POR DIÁRIA:{Money.centsToMaskMoney(totems_recharges?.value)}</td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(totems_recharges?.qtdSent * totems_recharges?.value)}</td>
          </tr>}

          {(closureResourcesEvent.internet.satellite > 0) && <tr>
            <td colSpan='8'>INTERNET SATÉLITE </td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.internet.satellite)}</td>
          </tr>}

          {(closureResourcesEvent.internet.wifi > 0) && <tr>
            <td colSpan='8'>SISTEMA WI-FI </td>
            <td className="text-tb-align-right">TOTAL: </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.internet.wifi)}</td>
          </tr>}
          {(resume.services_provided > 0) && <tr>
            <td colSpan='8' className="fw-bold">VALOR TOTAL DE SERVIÇOS + ADICIONAIS</td>
            <td className="text-tb-align-right fw-bold">TOTAL: </td>
            <td className="text-tb-align-right fw-bold" style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.services_provided)}
            </td>
          </tr>}
        </tbody>
      </table>
    </div>
  );
}

export default AdditionalServices;