import React, { lazy } from 'react';
import { authPages, financialMenu, demoPages, calendarMenu, othersMenu, topMenu } from '../menu';
import Event from '../pages/presentation/establishment/event/event';
import Establishment from '../pages/presentation/registers/establishment/establishment';
import Users from '../pages/presentation/registers/users/users';
import Sectors from '../pages/presentation/establishment/sectors/sectors';
import Totem from '../pages/presentation/establishment/totem';
import TotemRecharge from '../pages/presentation/establishment/totemRecharge';
import Products from '../pages/presentation/pdv/product/product';
import Categories from '../pages/presentation/pdv/categories/categories';
import SubCategories from '../pages/presentation/pdv/subCategories/subCategories';
import Printers from '../pages/presentation/sector/printers/printers';
import CategoryComplement from '../pages/presentation/pdv/categoryComplement/categoryComplement';
import Menus from '../pages/presentation/pdv/menus/menus';
import Entrance from '../pages/presentation/event/entrance';
// import ContactUs from '../pages/presentation/contactUs/ContactUs';
// import Documentations from '../pages/presentation/documentations/Documentations';
import SectorProducts from '../pages/presentation/sector/products';
import StockProducts from '../pages/presentation/sector/stockProducts';
import Settings from '../pages/presentation/event/settings';
import EarlyConsummationPage from '../pages/presentation/event/earlyConsummation';
import Operators from '../pages/presentation/event/operators';
import UsersOperators from '../pages/presentation/registers/usersOperators';
import OperatorsReports from '../pages/presentation/reports/operatorsReports';
import ClientsReports from '../pages/presentation/reports/clientsReports';
import Coordinators from '../pages/presentation/event/coordinators';
import AccessPassword from '../pages/presentation/event/accessPasswrod';
import EstabSettings from '../pages/presentation/establishment/settings'
import Sellers from '../pages/presentation/registers/sellers';
import ConfigUget from '../pages/presentation/configUget';
import EventStatus from '../pages/presentation/eventStatus';
import Stockist from '../pages/presentation/registers/stockist';
import ContractManager from '../pages/presentation/registers/contractManager';
import FinancialManager from '../pages/presentation/registers/financialManager';
import AdmContabil from '../pages/admContabil';
import Contracts from '../pages/contracts/Contracts';
import Defect from '../pages/presentation/defect';
import Reasons from '../pages/presentation/event/reasons';
import ContEvent from '../pages/contEvent';
import FiltroPorData from '../pages/presentation/reports/filtroPorData/FiltroData';
import CourtesyListPage from '../pages/presentation/event/courtesyList';
import PromotersLink from '../pages/presentation/event/promotersLink';
import BoxOfficeTime from '../pages/presentation/reports/boxOfficeTime';



const MAIN = {
  CALENDAR: lazy(() => import('../pages/calendar/Calendar'))
};


const LANDING = {
  FINANCIAL: lazy(() => import('../pages/financial/FinancialPage')),
};
const AUTH = {
  LOGIN: lazy(() => import('../pages/presentation/auth/Login')),
  PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
 
};
const PROFILE = {
  PROFILE: lazy(() => import('../pages/presentation/profile/Profile')),
};

const EXTERNAL = {
  OPERATOR_AUTO_REGISTER: lazy(() => import('../pages/presentation/operatorAutoRegister')),
  CLIENT_AUTO_REGISTER_HOME: lazy(() => import('../pages/PreRegistration/Home')),
  TERMS_OF_USE: lazy(() => import('../pages/termsOfUse')),

};

const presentation = [
  /**
   * Auth
   */
  {
    path: authPages.login.path,
    element: <AUTH.LOGIN />,
    exact: true,
  },
  {
    path: authPages.profile.path,
    element: < PROFILE.PROFILE/>,
    exact: true,

  },
  {
    path: authPages.signUp.path,
    element: <AUTH.LOGIN isSignUp />,
    exact: true,
  },
  {
    path: authPages.page404.path,
    element: <AUTH.PAGE_404 />,
    exact: true,
  },

  {
    path: calendarMenu.calendar.path,
    element: <MAIN.CALENDAR />,
    exact: true,
  },
  {
    path: topMenu.contracts.path,
    element: <Contracts />,
    exact: true,
  },

  /** ************************************************** */
  {
    path: othersMenu.operatorAutoRegister.path,
    element: <EXTERNAL.OPERATOR_AUTO_REGISTER />,
    exact: true,
  },
  /**
   * Landing
   */
  {
    path: othersMenu.preRegister.path,
    element: <EXTERNAL.CLIENT_AUTO_REGISTER_HOME />,
    exact: true,
  },

  {
    path: othersMenu.termsOfUse.path,
    element: <EXTERNAL.TERMS_OF_USE />,
    exact: true,
  },
  {
    path: topMenu.admContabil.path,
    element: <AdmContabil />,
    exact: true,
    min_level: topMenu.admContabil.min_level,
  },
  {
    path: topMenu.contEvent.path,
    element: < ContEvent />,
    exact: true,
    min_level: topMenu.contEvent.min_level,
  },
  {
    path: financialMenu.financial.path,
    element: <LANDING.FINANCIAL />,
    exact: true,
  },
  {
    // path: financialMenu.cashier.path,
    // element: <LANDING.Cashier />,
    // exact: true,
  },
  // {
  //   path: demoPages.documentations.path,
  //   element: <Documentations />,
  //   exact: true,
  // },
  // {
  //   path: demoPages.contactUs.path,
  //   element: <ContactUs />,
  //   exact: true,
  // },
  {
    path: demoPages.defect.path,
    element: <Defect />,
    exact: true,
  },
  // {
  //   path: demoPages.reports.path,
  //   element: <Reports />,
  //   exact: true,
  // },
  {
    path: demoPages.reports.subMenu.boxOfficeTime.path,
    element: <BoxOfficeTime />,
    exact: true,
    min_level: demoPages.reports.subMenu.boxOfficeTime.min_level,
  },
  {
    path: demoPages.reports.subMenu.operatorsReports.path,
    element: <OperatorsReports />,
    exact: true,
    min_level: demoPages.reports.subMenu.operatorsReports.min_level,
  },
  {
    path: demoPages.reports.subMenu.clientsReports.path,
    element: <ClientsReports />,
    exact: true,
    min_level: demoPages.reports.subMenu.clientsReports.min_level,
  },
  // {
  //   path: demoPages.reports.subMenu.cashReports.path,
  //   element: <CashReports />,
  //   exact: true,
  //   min_level: demoPages.reports.subMenu.cashReports.min_level,
  // },
  // {
  //   path: demoPages.reports.subMenu.totemReports.path,
  //   element: <TotemReports />,
  //   exact: true,
  //   min_level: demoPages.reports.subMenu.cashReports.min_level,
  // },
  // {
  //   path: demoPages.reports.subMenu.saleReports.path,
  //   element: <SalesReports />,
  //   exact: true,
  //   min_level: demoPages.reports.subMenu.saleReports,
  // },
  // {
  //   path: demoPages.reports.subMenu.reportConsumption.path,
  //   element: <ReportConsumption />,
  //   exact: true,
  //   min_level: demoPages.reports.subMenu.reportConsumption,
  // },
  {
    path: demoPages.reports.subMenu.filtroPorData.path,
    element: <FiltroPorData />,
    exact: true,
    min_level: demoPages.reports.subMenu.filtroPorData,
  },
  // {
  //   path: demoPages.reports.subMenu.stock.path,
  //   element: <Stock/>,
  //   exact: true,
  //   min_level: demoPages.reports.subMenu.stock,
  // },



  // {
  //   path: demoPages.settings.path,
  //   element: <Settings />,
  //   exact: true,
  // },



  /** ************************************************** */


  {
    path: demoPages.event.subMenu.entrance.path,
    element: <Entrance />,
    exact: true,
    min_level: demoPages.event.subMenu.entrance.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.establishment.path,
    element: <Establishment />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.establishment.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.sellers.path,
    element: <Sellers />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.sellers.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.stockist.path,
    element: <Stockist />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.stockist.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.contractManager.path,
    element: <ContractManager />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.contractManager.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.financialManager.path,
    element: <FinancialManager />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.financialManager.min_level,
  },

  {
    path: demoPages.establishment.subMenu.event.path,
    element: <Event />,
    exact: true,
    min_level: demoPages.establishment.subMenu.event.min_level,
  },
  {
    path: demoPages.establishment.subMenu.sectors.path,
    element: <Sectors />,
    exact: true,
    min_level: demoPages.establishment.subMenu.sectors.min_level,
  },
  {
    path: demoPages.establishment.subMenu.reasons.path,
    element: <Reasons />,
    exact: true,
    min_level: demoPages.establishment.subMenu.reasons.min_level,
  },
  {
    path: demoPages.establishment.subMenu.totems.path,
    element: <Totem />,
    exact: true,
    min_level: demoPages.establishment.subMenu.totems.min_level,
  },
  {
    path: demoPages.establishment.subMenu.totemsRecharge.path,
    element: <TotemRecharge />,
    exact: true,
    min_level: demoPages.establishment.subMenu.totemsRecharge.min_level,
  },
  {
    path: demoPages.establishment.subMenu.settings.path,
    element: <EstabSettings />,
    exact: true,
    min_level: demoPages.establishment.subMenu.settings.min_level,
  },
  {
    path: demoPages.event.subMenu.operators.path,
    element: <Operators />,
    exact: true,
    min_level: demoPages.event.subMenu.operators.min_level,
  },
  {
    path: demoPages.event.subMenu.coordinators.path,
    element: <Coordinators />,
    exact: true,
    min_level: demoPages.event.subMenu.coordinators.min_level,
  },

  {
    path: demoPages.event.subMenu.earlyConsummation.path,
    element: <EarlyConsummationPage />,
    exact: true,
    min_level: demoPages.event.subMenu.earlyConsummation.min_level,
  },
  {
    path: demoPages.event.subMenu.promoterLink.path,
    element: <PromotersLink />,
    exact: true,
    min_level: demoPages.event.subMenu.promoterLink.min_level,
  },
  {
    path: demoPages.event.subMenu.courtesyList.path,
    element: <CourtesyListPage />,
    exact: true,
    min_level: demoPages.event.subMenu.courtesyList.min_level,
  },

  {
    path: demoPages.pdvMenu.subMenu.categories.path,
    element: <Categories />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.categories.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.subCategories.path,
    element: <SubCategories />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.subCategories.min_level,
  },
  // {
  //   path: demoPages.pdvMenu.subMenu.complement.path,
  //   element: <Complement />,
  //   exact: true,
  //   min_level: demoPages.pdvMenu.subMenu.complement.min_level,
  // },
  {
    path: demoPages.pdvMenu.subMenu.categoryComplement.path,
    element: <CategoryComplement />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.categoryComplement.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.product.path,
    element: <Products />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.product.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.users.path,
    element: <Users />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.users.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.usersOperators.path,
    element: <UsersOperators />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.usersOperators.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.menus.path,
    element: <Menus />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.menus.min_level,
  },
  {
    path: demoPages.sector.subMenu.product.path,
    element: <SectorProducts />,
    exact: true,
  },
  {
    path: demoPages.sector.subMenu.stockProducts.path,
    element: <StockProducts />,
    exact: true,
  },
  // {
  //   path: demoPages.sector.subMenu.stockComponents.path,
  //   element: <StockComponents />,
  //   exact: true,
  // },
  // {
  //   path: demoPages.event.subMenu.cashier.path,
  //   element: <Cashiers />,
  //   exact: true,
  //   // min_level: demoPages.sector.subMenu.cashier.min_level,
  // },

  {
    path: demoPages.event.subMenu.settings.path,
    element: <Settings />,
    exact: true,
    min_level: demoPages.event.subMenu.settings.min_level,
  },
  {
    path: demoPages.event.subMenu.accessPassword.path,
    element: <AccessPassword />,
    exact: true,
  },
  {
    path: demoPages.sector.subMenu.printers.path,
    element: <Printers />,
    exact: true,
    min_level: demoPages.sector.subMenu.printers.min_level,
  },

  {
    path: demoPages.configUget.path,
    element: <ConfigUget />,
    exact: true,
    min_level: demoPages.configUget.min_level,
  },
  {
    path: demoPages.eventStatus.path,
    element: <EventStatus />,
    exact: true,
    min_level: demoPages.eventStatus.min_level,
  },
  // {
  //   path: demoPages.sector.subMenu.settings.path,
  //   element: <SectorSettings />,
  //   exact: true,
  //   min_level: demoPages.sector.subMenu.settings.min_level,
  // },
];
const contents = [...presentation];

export default contents;
