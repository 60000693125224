import { ApexOptions } from "apexcharts";
import Money from "../../../helpers/utils/money/money";

export const POR_HORA_CHAR_OPTIONS: ApexOptions = {
  chart: {
    height: 400,
    type: 'area',
    toolbar: {
      show: true,
      export: {
        csv: {
          filename: "Grafico-por-hora",
          headerCategory: 'HORAS',
        },
        svg: {
          filename: "Grafico-Por-hora",
        },
        png: {
          filename: "Grafico-por-hora",
        }
      }
    },
  },
  colors: [
    process.env.REACT_APP_PRIMARY_COLOR,
    process.env.REACT_APP_SECONDARY_COLOR,
    process.env.REACT_APP_SUCCESS_COLOR,
    process.env.REACT_APP_DANGER_COLOR
  ],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  tooltip: {
    x: {
      formatter: val => (val - 1) + ' Horas'
    },
    y: {
      formatter: val => Money.maskMoney(val)
    },
    theme: 'dark',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
}