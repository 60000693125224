import React from 'react';
import dateFormat from "dateformat";
import Footer from "../../Footer";
import { MONTH_PTBR } from "../../../types/dates";


const LastPageContract = ({ establishment, event }) => {


  const dataAtual = new Date();

  return (
    <div className='mt-2'>
      <div className='col-xxl-12 justify-content-around px-4'>
        <h6>Por ser expressão da verdade e estarem de comum acordo com o presente termo, firmam o presente.</h6>
        <h6 className='mt-2'>São Paulo, {dateFormat(dataAtual, 'dd')} de {MONTH_PTBR[dataAtual.getMonth()]} de {dateFormat(dataAtual, 'yyyy')}.</h6>
      </div>
      <div className='divFooter'>
        <div className='d-flex text-center flex-column col-xxl-12 justify-content-around mt-report  my-5 py-3'>
          <h6>UGET PDX TECNOLOGIA E SOLUÇÕES LTDA.</h6>
        </div>
        <div className='d-flex text-center flex-column col-xxl-12 justify-content-around mt-report my-4 py-3'>
          <h6>CLIENTE - {establishment?.name}</h6>
        </div>
        <div id='ugetWitnesses' className='row align-self-center justify-content-between mt-report  pt-5 mb-2 mt-xxl-5'>
          <div className='col-6  text-center '>
            <h6>TESTEMUNHA UGET</h6>
            <h6>{event?.witnesses.ugetWitnessName}</h6>
            <h6>CPF: {event?.witnesses.ugetWitnessCpf}</h6>
            <h6>E-MAIL: {event?.witnesses.ugetWitnessEmail}</h6>
          </div>
          <div className='col-6  text-center'>
            <h6 >TESTEMUNHA EVENTO</h6>
            <h6>{event?.witnesses.eventWitnessName}</h6>
            <h6>CPF: {event?.witnesses.eventWitnessCpf}</h6>
            <h6>E-MAIL: {event?.witnesses.eventWitnessEmail}</h6>
          </div>
        </div>
      </div>
      <Footer isContract={true} />
    </div>
    
  );
}

export default LastPageContract;