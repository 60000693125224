import React from "react";
import Logo from "../../Logo";
import dateFormat from "dateformat";

const HeaderFirstPage = ({ eventSelected, establishment }) => {
  console.log(eventSelected, 'ellla')
  return (
    <div className='row justify-content-between m-5'>
      <div className='col p-0 px-4'>
        <Logo width={200} black />
        <h6 className='my-3'>FECHAMENTO DE ORDEM DE SERVIÇO nº {eventSelected?.codeNumber} </h6>
        <h6 >REFERENTE A CONTRATO nº {establishment?.codeNumber} </h6> 
     
       
      </div>
      <div className=' col p-0 text-end'>
        <h6>{eventSelected?.name}</h6>
        <h6>DATA: {dateFormat(eventSelected?.date.toDate(), 'dd/mm/yyyy')} a {dateFormat(eventSelected?.endDate.toDate(), 'dd/mm/yyyy')}.</h6>
      </div>
     
    </div>
    
  );
}

export default HeaderFirstPage;